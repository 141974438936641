import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import styled from 'styled-components'
import { getCurrentLangKey } from 'ptz-i18n';

import Layout from "../components/layout"
import HeaderSection from '../components/Services/HeaderSection/HeaderSection'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const ServicesPage = props => {
  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
 
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const servicesdata = get(props, 'data.services.nodes', [])
  const seoData = get(props, 'data.serviceDetail.nodes[0].seo', [])
  const pageData = get(props, 'data.pageData.nodes[0]', [])

  let dataObject = {
    title: seoData.title,
    description: seoData.description,
    lang: 'nl',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.pageData.nodes[0].slug,
    slugEn: props.data.translatedUrl.nodes[0].slug,
  }

  return (
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...dataObject} />
      <Container>
        <HeaderSection data={servicesdata} url={homeLink} pageData={pageData}/>
      </Container>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  pageData:allCraftServicesPageServicesPageEntry(filter: {siteId: {eq: 2}}) {
    nodes{
      title
      pageIntro
      slug
    }
  }
  translatedUrl:allCraftServicesPageServicesPageEntry(filter: {siteId: {eq: 1}}) {
    nodes{
      slug
    }
  }
  services:allCraftServicesServicesEntry(filter: {siteId: {eq: 2}}) {
    nodes {
      servicesSection {
        ... on craft_servicesSection_service_BlockType {
          servicetitle
          serviceparagraph
          serviceimage {
            url
          }
        }
      }
      slug
      language
      workServiceIntro
      WorkServicesText
      workServiceTitle
      workServiceImage {
        url
      }
    }
  }
  serviceDetail:allCraftServicesPageServicesPageEntry(filter: {siteId: {eq: 2}}) {
      nodes {
        title
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
      }
    }
}
`
